import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import { quoteContext } from '../../../provider';

import '../../assets/styles/pages/quote/step-five.scss';
import ContactlessPopup from '../../components/quotes/contactless-popup';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProgressBar from '../../components/quotes/progress-bar';
import QuoteNav from '../../components/quotes/quote-nav';
import BackArrow from '../../images/quote/icon-arrow-left.inline.svg';
import InfoIcon from '../../images/quote/info-icon.inline.svg';

/* global window */
const QuoteStepFive = () => {
  const [contactlessRepair, setContactlessRepair] = useState([]);
  const [contactlessPopup, setContactlessPopup] = useState(false);
  let { quoteForm } = useContext(quoteContext);

  useEffect(() => {
    if (Object.keys(quoteForm).length > 0) {
      setContactlessRepair(quoteForm.contactlessRepair);
    } else {
      navigate('/quote');
    }
  }, [quoteForm]);

  const handleInputChange = (event) => {
    const val = event.target.value;
    setContactlessRepair(val);
  };

  const handleSubmit = (event, context) => {
    event.preventDefault();
    context.addToQuoteForm({
      ...quoteForm,
      contactlessRepair: contactlessRepair
    });
    navigate('/quote/step-six');
  };

  const hasDescription = () => {
    if (contactlessRepair) {
      return (contactlessRepair.length > 0);
    }

    return false;
  }

  return (
    <Layout wrapperClass="quote-container" hideHeaderFooter={true}>
      <quoteContext.Consumer>
        {context => (
          <React.Fragment>
            <ProgressBar step="5" />
            <SEO title="DingGo | Car Quote - Choose repair type" description="Upload your photos and describe the damage to compare car repair quotes online, fast and free. Snap. Compare. Repair." />
            <div className="quote-wrapper step-five">
              <div className="container xs">
                <QuoteNav step="5" />
                <h1>Contactless Repair</h1>
                <p className="intro">
                  Our repairers also offer options for contactless repairs. Let us know what type of repairers you want quotes from.
                </p>
                <span className="open-contactless-repair" onClick={(e) => {
                  e.preventDefault();
                  setContactlessPopup(!contactlessPopup);
                }}><InfoIcon /> What is a contactless repair?</span>
                <form onSubmit={(e) => { handleSubmit(e, context); }}>
                  <div className="radio-btn-wrapper">
                    <div className="radio-container">
                      <label className="radio-label">Contactless Repairers Only
                      <input type="radio" onChange={(e) => { handleInputChange(e); }} value="Contactless Repairers Only" name="contactlessRepair"
                          checked={contactlessRepair === "Contactless Repairers Only"} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="radio-container">
                      <label className="radio-label">Get quotes from all repairers
                      <input type="radio" onChange={(e) => { handleInputChange(e); }} value="Get quotes from all repairers" name="contactlessRepair"
                          checked={contactlessRepair === "Get quotes from all repairers"} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="form-btn-wrapper">
                    <Link to="/quote/step-four" className="arrow-cta"><BackArrow />Back</Link>
                    <button className={`btn ${hasDescription() ? '' : 'disable'}`} type="submit" id="button-click-to-step-6">Next</button>
                  </div>
                </form>
              </div>
            </div>
            <ContactlessPopup contactlessPopup={contactlessPopup} setContactlessPopup={setContactlessPopup} />
          </React.Fragment>
        )}
      </quoteContext.Consumer>
    </Layout>
  );
};

export default QuoteStepFive;
